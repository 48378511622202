import * as msal from "@azure/msal-browser";
import { store } from "../store";
import * as actions from '../store/actions';
import * as apiService from './apiService';
import * as attributesService from "./attributesService";
import * as userModel from '../models/userModel';
import * as tokenModel from '../models/tokenModel';
import * as rankingService from './rankingService';
import axios from "axios";
import moment from 'moment';
import { createConfigModel } from "../models/configModel";

let msalConfig = {
  auth: {
    clientId: '',
    authority: '',
    // redirectUri: ''
  },
};
let msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  async initialize(config = createConfigModel()) {
    try {
      let _params = {
        clientId: '',
        authorityId: '',
      }

      for (let i = 0; i < config.url.length; i++) {
        const _item = config.url[i].split(':');
        _params[_item[0]] = _item[1];
      }

      if (_params.clientId && _params.authorityId) {
        msalConfig = {
          auth: {
            clientId: _params.clientId,
            authority: `https://login.microsoftonline.com/${_params.authorityId}`,
          },
        };
      } else {
        throw new Error('USE-SSO-MICROSOFT_INVALID_PARAMS');
      }

      msalInstance = new msal.PublicClientApplication(msalConfig);

      await msalInstance.initialize();
    } catch (error) {
      console.log('Error initialize', error);
      throw error;
    }
  },

  async openLoginPopup() {
    try {
      let _res = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"],
      });

      let _user;
      if (_res.account.username && _res.account.name) {
        _user = await checkIfUserExists(_res.account.username);
      }

      let _idMicePlus = '';
      if (!_user) {
        _user = await registerUserApi(_res.account.name, _res.account.username);

        await createUserAppPlus(_res.account.name, _res.account.username, _user.id);

        _idMicePlus = await getIdMicePlus(_res.account.username);

        await updateInternalCodeMice(_user.id, _idMicePlus);
      } else {
        _idMicePlus = await getIdMicePlus(_res.account.username);
      }

      let _return = userModel.createUserModel(
        _user.id,
        store.getState().event.id,
        _user.name,
        _user.email,
        _user.institution ? _user.institution : "",
        _user.avatarProfileLink ? _user.avatarProfileLink : "",
        _user.hallFirstTerm,
        _user.hallSecondTerm,
        null,
        null,
        null,
        _user.groupId,
        _idMicePlus,
        _idMicePlus,
        _user.password,
        _user.company,
        _user.visibleToChat,
        _user.ranking && _user.ranking.levelId
          ? _user.ranking.levelId
          : null,
        _user.ranking && _user.ranking.level && _user.ranking.level.name
          ? _user.ranking.level.name
          : null,
        _user.ranking && _user.ranking.points ? _user.ranking.points : null,
        _user.specialty,
        _user.ranking && _user.ranking.position
          ? _user.ranking.position
          : null
      );

      let _userToken = tokenModel.createTokenModel(
        _user.accessToken || "",
        moment().format()
      );
      store.dispatch(actions.setToken(_userToken));

      await setPointsOnMicePlus(_return.internalCode);

      return _return;

    } catch (error) {
      console.log('Error openLoginPopup', error);
      throw error;
    }

    async function checkIfUserExists(email = '') {
      try {
        let _user;
        try {
          let _token = await apiService.getToken(email);
          const options = {
            url: apiService.getUrl().direct + 'globo/api/event/guest/login',
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + _token,
            },
            data: {
              type: 2,
              text: email,
              eventId: store.getState().event.id,
              password: null,
            },
          };

          let _res = await axios(options);

          _user = _res.data.data;
          _user.accessToken = _res.data.message;

          return _user;
        } catch (error) {
          console.log("Error getUser", error);
        }

        if (_user && _user.id && _user.id > 0) {
          return _user;
        } else {
          return null;
        }
      } catch (error) {
        console.log("Error checkIfUserExists", error);
        return null;
      }
    };

    async function registerUserApi(name = '', email = '') {
      let _attributesList = await attributesService.getAttributes(store.getState().event.id);
      let _attributeName = _attributesList.find(a => { return a.dbReference === 'Nome' });
      let _attributeNEmail = _attributesList.find(a => { return a.dbReference === 'Email' });

      let token = await apiService.getToken();

      const attributesApi = [
        {
          attributeId: _attributeName.id,
          value: name,
        },
        {
          attributeId: _attributeNEmail.id,
          value: email,
        }
      ];

      return new Promise((resolve, reject) => {
        const options = {
          url: apiService.getUrl().direct + 'globo/api/event/guest/register',
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token,
          },
          data: [
            {
              contractId: parseInt(store.getState().event.contractId),
              eventId: parseInt(store.getState().event.id),
              attributes: attributesApi,
            },
          ],
        };
        axios(options)
          .then((res) => {
            if (res.data.success && res.data.data.guest && res.data.data.guest.id) {
              let _user = res.data.data.guest;
              _user.accessToken = res.data.data.token;

              resolve(_user);
            } else {
              reject(res.data);
            }
            return;
          })
          .catch((error) => {
            console.log("Erro registerUserApi", error);
            reject(error);
            return error;
          });
      });
    };

    async function createUserAppPlus(
      name = '',
      email = '',
      idUser = '',
    ) {
      try {
        const _options = {
          url: `https://gateway-mobile-plus.sistemasinteegra.com.br/global/credentials`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            idEvento: "50acc980-5b59-48b6-bca1-c33e713827cf",
            email: email,
            senha: '',
            nome: name,
            grupos: null,
            codeQrCode: idUser
          },
        };

        await axios(_options);

      } catch (error) {
        console.log('Error createUser', error);
        throw error;
      }
    }

    async function getIdMicePlus(
      email = '',
    ) {
      try {
        const _options = {
          url: `${apiService.getUrl().globoApi}userMicePlus/getId?email=${email}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        let _ret = await axios(_options);

        if (!_ret.data.success) {
          throw new Error(_ret.data.message);
        }

        return _ret.data.data.id;

      } catch (error) {
        console.log('Error createUser', error);
        throw error;
      }
    }

    async function updateInternalCodeMice(guestId, micePlusId) {
      let _token = await apiService.getTokenByEventId(store.getState().event.id);
      const options = {
        url: apiService.getUrl().hall + "event/guest/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + _token,
        },
        data: {
          guestId: guestId,
          contractId: parseInt(store.getState().event.contractId),
          eventId: parseInt(store.getState().event.id),
          groupId: null,
          confirmationStatus: "CN",
          attributes: [{
            attributeId: 609169,
            value: micePlusId,
          }],
        },
      };
      await axios(options)
    }

    async function setPointsOnMicePlus(internalCode = '') {
      try {
        await rankingService.setAdminPlusRankingPoints(
          'ACESSO',
          false,
          internalCode,
          '50acc980-5b59-48b6-bca1-c33e713827cf'
        );
      } catch (error) {
        console.log('Error setAdminPlusRankingPoints', error);
      }
    }
  },
}
